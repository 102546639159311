<template>
  <MCard class="query-card">
    <div class="query-header">
      <div class="qc-title">
        <MIcon class="qc-icon" name="m-flight-takeoff" width="16" height="16" />
        <div class="qc-label">{{ query.route_type }}</div>
      </div>

      <div class="qc-title" :title="travelerCountTitle">
        <MIcon class="qc-icon" name="m-airline-seat" width="16" height="16" />
        <div class="qc-label">{{ travelerCountLabel }}</div>
      </div>

      <div class="qc-title">
        <MIcon class="qc-icon" name="m-flight-class" width="16" height="16" />
        <div class="qc-label">{{ query.cabin_class }}</div>
      </div>
    </div>

    <MDivider class="query-divider" />

    <div class="query-routes">
      <template v-for="(queryLeg, index) in queryLegs" :key="index">
        <div
          :class="['qc-route', { active: queryLeg.active || isPairViewActive }]"
        >
          <div class="qc-route-label">
            {{ queryLeg.origin }}
          </div>
          <MIcon
            class="qc-route-icon"
            name="m-one-way-icon"
            width="16"
            height="16"
          />
          <div class="qc-route-label">
            {{ queryLeg.destination }}
          </div>
        </div>
      </template>
    </div>

    <div class="query-financial-profile" v-if="selectedFinancialProfile">
      <div class="fp-name">
        {{ selectedFinancialProfile.financial_profile_name }}
        <span v-if="selectedFinancialProfile.sector">
          {{ selectedFinancialProfile.sector }}
        </span>
      </div>
      <div class="fp-info">
        <div class="fp-id">{{ selectedFinancialProfile.platform_id }}</div>
        <div
          :class="[
            'fp-status',
            {
              active: selectedFinancialProfile.status === 'ACTIVE',
            },
          ]"
        >
          ({{ selectedFinancialProfile.status }})
        </div>
        <div class="fp-is-default">
          ({{
            selectedFinancialProfile.is_default ? "DEFAULT" : "NOT DEFAULT"
          }})
        </div>
      </div>
    </div>

    <div class="query-timer">
      <MChip
        class="qc-timer-chip"
        :variant="`${timedOut ? 'error' : 'primary'}`"
        border-less
        prepend-icon="m-timer"
      >
        <span class="qc-timer-chip-label">{{ formatedTime }}</span>
      </MChip>

      <div class="qc-timer-message">Book now before the search expires</div>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MCard, MIcon, MDivider, MChip } from "@aeroglobe/ag-core-ui";

import { QueryLeg, QueryType } from "../../types";
import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import UTILS from "@/ag-portal-common/utils";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";

export default defineComponent({
  name: "QueryCard",
  props: {
    query: {
      type: Object as PropType<QueryType>,
      required: true,
    },
    isPairView: {
      type: Boolean,
      default: false,
    },
    selectedQueryLegIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ["expire:time"],
  components: { MCard, MIcon, MDivider, MChip },
  computed: {
    selectedFinancialProfile(): IFinancialProfile | undefined {
      if (!this.isSuperUser) {
        return;
      }

      const financialProfiles: IFinancialProfile[] =
        this.$store.getters.issuanceFinancialProfiles;

      const profile: IFinancialProfile | undefined = financialProfiles?.find(
        (x: IFinancialProfile) =>
          x?.public_id === this.query.financial_profile_id
      );

      return profile;
    },
    isSuperUser(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
    isPairViewActive(): boolean {
      return this.isPairView && this.query.route_type === ROUTE_TYPE.RETURN;
    },
    travelerCountLabel(): string {
      const { adult_count, child_count, infant_count } =
        this.query.traveler_count;
      const adultCount: number = Number(adult_count) ?? 0;
      const childCount: number = Number(child_count) ?? 0;
      const infantCount: number = Number(infant_count) ?? 0;
      const total: number = adultCount + childCount + infantCount;

      return `${total} Traveler${total > 1 ? "s" : ""}`;
    },
    travelerCountTitle(): string {
      const { adult_count, child_count, infant_count } =
        this.query.traveler_count;
      const adultCount: number = Number(adult_count) ?? 0;
      const childCount: number = Number(child_count) ?? 0;
      const infantCount: number = Number(infant_count) ?? 0;

      return `Adult(${adultCount}x) | Child(${childCount}x) | Infant(${infantCount}x)`;
    },
    queryLegs(): QueryLeg[] {
      switch (this.query.route_type) {
        case ROUTE_TYPE.ONEWAY:
          return [
            {
              origin: this.query.origin ?? "",
              destination: this.query.destination ?? "",
              departure_date: this.query.departure_date ?? "",
              active: this.selectedQueryLegIndex === 0,
            },
          ];
        case ROUTE_TYPE.RETURN:
          return [
            {
              origin: this.query.origin ?? "",
              destination: this.query.destination ?? "",
              departure_date: this.query.departure_date ?? "",
              active: this.selectedQueryLegIndex === 0,
            },
            {
              origin: this.query.destination ?? "",
              destination: this.query.origin ?? "",
              departure_date: this.query.departure_date ?? "",
              active: this.selectedQueryLegIndex === 1,
            },
          ];
        case ROUTE_TYPE.MULTI_CITY:
          return (this.query.legs ?? []).map((leg, index) => ({
            ...leg,
            active: this.selectedQueryLegIndex >= index,
          }));
        default:
          return [];
      }
    },
    formatedTime(): string {
      return this.formatDuration(this.timeRemaining);
    },
  },
  data() {
    return {
      timeRemaining: 600,
      timerId: 0,
      timedOut: false,
    };
  },
  methods: {
    formatDuration(durationInSeconds: number) {
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = durationInSeconds % 60;

      return `00:${minutes < 10 ? "0" : ""}${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    },
    onStartTimerHandler() {
      clearInterval(this.timerId);

      this.timeRemaining = 600;
      this.timedOut = false;

      const timer = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          this.timedOut = true;
          clearInterval(this.timerId);
          this.$emit("expire:time", this.timedOut);
        }
      }, 1000);

      this.timerId = Number(timer);
    },
  },
  mounted() {
    this.onStartTimerHandler();
  },
  beforeUnmount() {
    clearInterval(this.timerId);
  },
});
</script>

<style scoped lang="css">
.query-card {
  color: var(--m-secondary-color);
}

.query-card .query-header {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
.query-card .query-header .qc-title {
  display: flex;
  gap: 4px;
  align-items: center;
}
.query-card .query-header .qc-title .qc-label {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.query-card .query-divider {
  margin: 8px 0;
}

.query-card .query-routes {
  display: flex;
  gap: 8px 16px;
  flex-wrap: wrap;

  margin-bottom: 16px;
}

.query-card .query-routes .qc-route {
  display: flex;
  gap: 8px;
  align-items: center;
}
.query-card .query-routes .qc-route.active {
  color: var(--m-primary-color);
  font-weight: 600;
}

.query-card .query-routes .qc-route .qc-route-label {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.query-card .query-header .qc-title .qc-icon,
.query-card .query-routes .qc-route .qc-route-icon {
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.query-card .query-financial-profile {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;

  margin-bottom: 16px;
}
.query-card .query-financial-profile .fp-info {
  display: flex;
  align-items: center;
  gap: 4px;
}
.query-card .query-financial-profile .fp-info .fp-id {
  font-weight: 500;
}
.query-card .query-financial-profile .fp-info .fp-status {
  color: var(--m-danger-color);
}
.query-card .query-financial-profile .fp-info .fp-status.active {
  color: var(--m-success-color);
}
.query-card .query-financial-profile .fp-info .fp-is-default {
  color: var(--m-primary-color);
}

.query-card .query-timer .qc-timer-chip {
  justify-content: center;
}
.query-card .query-timer .qc-timer-chip .qc-timer-chip-label {
  color: var(--m-secondary-color);
  font-size: 16px;
  font-weight: 600;

  width: 68px;
  text-align: center;
  display: inline-block;
}

.query-card .query-timer .qc-timer-message {
  text-align: center;

  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;

  margin-top: 12px;
}
</style>
