import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54ae476c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fit_quick_search_wrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.countryThumb)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.countryThumb,
          class: "img"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MTypography, {
        type: "body",
        class: "country"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.countryName), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_MTypography, {
        type: "label",
        class: "description"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.description), 1)
        ]),
        _: 1
      })
    ])
  ]))
}