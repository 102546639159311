import RestClientService from "@/ag-portal-common/services/restClient.service";
import { APIResponse, FlightRulesResponse, QueryType } from "../types";
import {
  FLIGHT_API_PATH,
  FLIGHT_BOOKING_INITIATE_PATH,
  FLIGHT_RULES_PATH,
} from "@/ag-flight-components/constants/apiEndPoints";
import {
  BookingResponse,
  FlightPreBookingTokenType,
  FlightResponse,
  FlightRuleParams,
} from "@/ag-flight-components/types";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { IAGErrorResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";

class FlightService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public flights(params: QueryType): Promise<APIResponse<FlightResponse>> {
    const response = this.restClient.post(
      FLIGHT_API_PATH.POST,
      params
    ) as Promise<APIResponse<FlightResponse>>;

    return response;
  }

  public async initiateBooking(
    params: FlightPreBookingTokenType,
    successCallback: (bookingId: string) => void,
    errorCallback?: () => void
  ): Promise<void> {
    try {
      const response = (await this.restClient.post(
        FLIGHT_BOOKING_INITIATE_PATH.POST,
        params
      )) as APIResponse<BookingResponse>;

      if (response.success && response.status === StatusCodes.OK) {
        const bookingId = response.data.data.booking_id;
        successCallback(bookingId);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      this.handleError(error);

      errorCallback && errorCallback();
    }
  }

  public async getFlightRules(
    params: FlightRuleParams,
    successCallback: (fareRules: FlightRulesResponse) => void,
    errorCallback?: () => void
  ): Promise<void> {
    try {
      const response = (await this.restClient.post(
        FLIGHT_RULES_PATH.POST,
        params
      )) as APIResponse<FlightRulesResponse>;

      if (response.success && response.status === StatusCodes.OK) {
        const fareRules = response.data.data;
        successCallback(fareRules);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      errorCallback && errorCallback();
    }
  }

  private handleError(error: unknown): void {
    const exception = error as IAGErrorResponse;

    notificationService.type = NOTIFICATION_TYPES.ERROR;
    notificationService.description =
      exception.message || exception.error || NOTIFICATION_MESSAGES.DEFAULT;
    notificationService.triggerNotification();
  }
}

export default FlightService;
