import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a0850e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_2 = { class: "status-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MCard, { class: "organizations-heading" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AgIconInfoBar, {
            "test-id": "",
            title: "Organizations",
            class: "section-title",
            mPrepandIcon: "m-organisations"
          }),
          _createElementVNode("div", null, [
            _createVNode(_component_MButton, {
              onClick: _ctx.onClickCreateNew,
              type: "filled"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Create new organization ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_MCard, null, {
      default: _withCtx(() => [
        _createVNode(_component_MDataTable, {
          headers: _ctx.headers,
          "is-loading": _ctx.$store.getters.isFetchingOrganizations,
          data: _ctx.transformTableData,
          "items-per-page": 30,
          "has-search": true,
          simpleDataTable: true
        }, {
          public_id: _withCtx(({ item }) => [
            _createVNode(_component_router_link, {
              to: `${_ctx.PATH.ORGANIZATION}/${item.id}`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MTypography, {
                  class: "description td-full-width organization-id",
                  type: "body"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.public_id), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          status: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_MChip, {
                class: "pricing-chip",
                variant: _ctx.organizationStatusClass(item.status)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.status), 1)
                ]),
                _: 2
              }, 1032, ["variant"])
            ])
          ]),
          action: _withCtx(({ item }) => [
            _createVNode(_component_MButton, {
              type: "filled",
              onClick: ($event: any) => (_ctx.onClickUpdate($event, item.id)),
              testId: 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, `organization-${item.id}-action`)
          
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.action), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "testId"])
          ]),
          _: 1
        }, 8, ["headers", "is-loading", "data"])
      ]),
      _: 1
    })
  ], 64))
}