<template>
  <MCard class="filters-card">
    <div class="fc-header fc-row">
      <div class="fc-title">Filter By</div>
      <div class="fc-action" @click="onResetAllFiltersHandler">Reset All</div>
    </div>

    <div class="fc-spacer"></div>

    <div class="fc-collapse" v-if="modelValue.price">
      <div class="fc-header fc-header fc-row">
        <div class="fc-title">Price Range</div>
        <MIcon
          class="fc-icon"
          name="m-dropdown"
          width="20"
          height="20"
          @click="showPrice = !showPrice"
        />
      </div>

      <div :class="['fc-content', { active: showPrice }]">
        <div class="fc-header fc-row">
          <div class="fc-row">
            <span>Upto {{ currency }}</span>
            <MTextfield
              :input-value="priceRange.toString()"
              @update:input-value="onChangeInputHandler"
              dense
              type="number"
              class="fc-input"
              :disabled="minPrice <= 0"
            />
          </div>
          <div class="fc-action" @click="onResetPriceHandler">Clear</div>
        </div>

        <MSlider
          class="fc-slider"
          v-model="priceRange"
          :min="minPrice"
          :max="maxPrice"
          full-width
          :disabled="minPrice <= 0"
        />
      </div>

      <MDivider />
    </div>

    <div class="fc-collapse" v-if="modelValue.stops.length">
      <div class="fc-header fc-header fc-row">
        <div class="fc-title">Stops</div>
        <MIcon
          class="fc-icon"
          name="m-dropdown"
          width="20"
          height="20"
          @click="showStops = !showStops"
        />
      </div>

      <div :class="['fc-content', { active: showStops }]">
        <div class="fc-header fc-row">
          <div>Select All Stops</div>
          <MToggle
            v-model="toggleStops"
            @update:model-value="modelValue.toggleStops($event)"
          />
        </div>

        <div class="fc-filter-row">
          <template v-for="(stop, index) in modelValue.stops" :key="index">
            <MCheckbox v-model="stop.value" :size="12" :label="stop.label" />
          </template>
        </div>
      </div>

      <MDivider />
    </div>

    <div class="fc-collapse" v-if="modelValue.times.length">
      <div class="fc-header fc-header fc-row">
        <div class="fc-title">Departure Time</div>
        <MIcon
          class="fc-icon"
          name="m-dropdown"
          width="20"
          height="20"
          @click="showDates = !showDates"
        />
      </div>

      <div :class="['fc-content', { active: showDates }]">
        <div class="fc-header fc-row">
          <div>Select All</div>
          <MToggle
            v-model="toggleTimes"
            @update:model-value="modelValue.toggleTimes($event)"
          />
        </div>

        <div class="fc-filter-row">
          <template v-for="(time, index) in modelValue.times" :key="index">
            <div class="fc-row">
              <MCheckbox v-model="time.value" :size="12" :label="time.label" />
              <MIcon
                v-if="time.icon"
                class="fc-icon"
                :name="time.icon"
                width="20"
                height="20"
              />
            </div>
          </template>
        </div>
      </div>

      <MDivider />
    </div>

    <div class="fc-collapse" v-if="modelValue.airlines.length">
      <div class="fc-header fc-header fc-row">
        <div class="fc-title">Airlines</div>
        <MIcon
          class="fc-icon"
          name="m-dropdown"
          width="20"
          height="20"
          @click="showAirlines = !showAirlines"
        />
      </div>

      <div :class="['fc-content', { active: showAirlines }]">
        <div class="fc-header fc-row">
          <div>Select All Airlines</div>
          <MToggle
            v-model="toggleAirlines"
            @update:model-value="modelValue.toggleAirlines($event)"
          />
        </div>

        <div class="fc-filter-row">
          <template v-for="(airline, index) in filteredAirlines" :key="index">
            <div class="fc-row">
              <MCheckbox
                v-model="airline.value"
                :size="12"
                :label="airline.label"
              />
              <div class="fc-label">({{ airline.count }})</div>
            </div>
          </template>
        </div>

        <template v-if="modelValue.airlines.length > 5">
          <div class="fc-spacer"></div>

          <div class="fc-row">
            <div class="fc-action" @click="showAllAirlines = !showAllAirlines">
              View {{ showAllAirlines ? "Less" : "More" }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  MCard,
  MIcon,
  MDivider,
  MSlider,
  MToggle,
  MCheckbox,
  MTextfield,
} from "@aeroglobe/ag-core-ui";

import { FlightFilters } from "../../models/filters";
import { Filter } from "../../types";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "FiltersCard",
  components: {
    MCard,
    MIcon,
    MDivider,
    MSlider,
    MToggle,
    MCheckbox,
    MTextfield,
  },
  props: {
    modelValue: {
      type: Object as PropType<FlightFilters>,
      required: true,
    },
    minPrice: {
      type: Number,
      default: 0,
    },
    maxPrice: {
      type: Number,
      default: 10,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    filteredAirlines(): Filter[] {
      return this.modelValue.airlines.slice(
        0,
        this.showAllAirlines ? this.modelValue.airlines.length : 5
      );
    },
    currency(): string {
      return UTILS.getCurrency() ?? "PKR";
    },
  },
  watch: {
    priceRange(newPrice: number): void {
      this.modelValue.updatePrice(Number(newPrice));
    },
    modelValue: {
      handler(filters: FlightFilters): void {
        if (filters.price.count === 0) {
          this.priceRange = 0;
        }

        this.toggleStops = filters.stops.every((stop) => stop.value);
        this.toggleTimes = filters.times.every((time) => time.value);
        this.toggleAirlines = filters.airlines.every(
          (airline) => airline.value
        );
      },
      deep: true,
    },
  },
  data() {
    return {
      priceRange: 0,
      stop: false,

      showPrice: true,
      showStops: true,
      showDates: true,
      showAirlines: true,

      showAllAirlines: false,

      toggleStops: false,
      toggleTimes: false,
      toggleAirlines: false,
    };
  },
  methods: {
    onResetPriceHandler(): void {
      this.priceRange = 0;
    },
    onResetAllFiltersHandler(): void {
      this.onResetPriceHandler();
      this.modelValue.resetAll();
    },
    getFormattedCurrency(amount: number): string {
      return UTILS.getFormattedCurrency(amount);
    },
    onChangeInputHandler(value: string): void {
      const range = Number(value);

      if (isNaN(range)) {
        return;
      }

      if (range < 0) {
        this.priceRange = 0;
      } else if (range < this.minPrice) {
        this.priceRange = this.minPrice;
      } else if (range > this.maxPrice) {
        this.priceRange = this.maxPrice;
      } else {
        this.priceRange = range;
      }
    },
  },
});
</script>

<style scoped lang="css">
.filters-card {
  color: var(--m-secondary-color);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.fc-header {
  margin-bottom: 6px;
  font-weight: 500;
}

.fc-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.fc-spacer {
  height: 24px;
}

.fc-content {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  animation: collapse 0.2s ease-in-out forwards;
}

.fc-content.active {
  animation: expand 0.2s ease-in-out forwards;
}

.fc-collapse:not(:last-child) {
  margin-bottom: 20px;
}

.fc-action {
  color: var(--m-primary-color);
  text-decoration: underline;
  cursor: pointer;

  user-select: none;
}

.fc-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
}

.fc-icon:hover {
  background-color: var(--m-secondary-accent-color);
}

.fc-title {
  font-weight: 500;
}

.fc-input {
  max-width: 100px;
  margin: 0;
}

.fc-slider {
  margin-top: 12px;
}

.fc-filter-row {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 16px 6px 0px;
}

@keyframes expand {
  0% {
    max-height: 0;
    padding: 0 6px 0;
  }
  50% {
    max-height: 50px;
    padding: 8px 6px 11px;
  }
  100% {
    max-height: 1000px;
    padding: 16px 6px 22px;
  }
}
@keyframes collapse {
  0% {
    max-height: 1000px;
    padding: 16px 6px 22px;
  }
  50% {
    max-height: 50px;
    padding: 8px 6px 11px;
  }
  100% {
    max-height: 0;
    padding: 0 6px 0;
  }
}
</style>
