import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import { ROUTE_TYPE } from "@/ag-portal-common/enums/ROUTE_TYPE";
import { TourDetailResponse } from "../tours";

export enum HotelLocationType {
  CITY = "CITY",
  PROPERTY = "PROPERTY",
}

export type HotelSearchSuggestion = {
  search_id: string;
  display_name: string;
  sub_display_name: string;
  type: HotelLocationType;
  image?: string;
  label?: string;
  description?: string;
};

export type RoomOccupancyChildrenParams = {
  age: number;
};

export type RoomOccupancy = {
  adults: number;
  children?: Array<RoomOccupancyChildrenParams>;
};

export type Amenity = {
  name: string;
  id: number;
  icon: string | null;
};

export type PropertyImage = {
  title: string;
  url: string;
};

export type CancellationPolicy = {
  id: number;
  startFrom: string;
  endAt: string;
  penalty: string;
  description: string;
};

export type Rate = {
  id: string;
  available_rooms: number;
  rate_class: string;
  deposit_required: number;
  cancellation_policy: Array<CancellationPolicy>;
  gross_rate: number;
  selling_rate: number;
  tax: number;
  fee: number;
  payment_type: string;
  meta: null;
};

export type StopSell = {
  msg: string;
  start: string;
  end: string;
};

export type Room = {
  room_id: string;
  room_name: string;
  room_description: string;
  board_basis: string;
  rates: Array<Rate>;
  price_per_person: boolean;
  adults: number;
  booking_policy: string;
  children: number;
  images: Array<PropertyImage>;
  infants: number;
  is_refundable: boolean;
  stop_sell: Array<StopSell>;
};

export type RoomCancellationTerm = {
  start_date: string;
  end_date: string;
  cancellation_amount: cancellation_amount;
  default_currency_total_cost: number;
};

type grossPrice = {
  value: number;
  currency: string;
};

type cancellation_amount = {
  value: number;
  currency: string;
};
type roomReservationDetails = {
  id: number;
  cancellation_terms: RoomCancellationTerm[];
  stay_qoute_id: string;
  total_cost: string;
  default_currency_total_cost: string;
  non_refundable: boolean;
  meal_board_basis: string;
  expires_at: string;
  room_type: string;
  currency_code: string;
  default_currency_code: string;
  check_in: string;
  check_out: string;
  other_inclusions: string[];
};

export type Property = {
  property_id: string;
  property_name: string;
  description: string;
  name: string;
  rating: number;
  city_name: string;
  country_name: string;
  address_line_1: string;
  address_line_2: string | null;
  non_refundable: boolean;
  issue_now_pay_later: boolean;
  images: Array<PropertyImage>;
  gross_price: grossPrice;
  main_image_url: string;
  supplier: string;
  thumbnail_image: any[];
  room_quotes: any[];
  facilities: any[];
  default_currency_code: string;
  room: roomReservationDetails;
  price: grossPrice;
  address1: string;
  city: string;
  country: string;
};

export type HotelSearchQuery = {
  checkin: string;
  checkout: string;
  rooms_occupancy: Array<RoomOccupancy>;
  filters: any;
  page?: number;
  destination: HotelSearchSuggestion;
};

export type PropertyDetail = {
  property_name: string;
  id: number;
  name: string;
  description: string | null;
  policy: string;
  resort: string;
  accommodation_type: string;
  rating: string;
  city: string;
  country: string;
  address1: string;
  address_line_1: string;
  address2: string;
  free_cancellation: boolean;
  featured: boolean;
  instant_confirmation: boolean;
  advance_payment: boolean;
  reserve_now_pay_later: boolean;
  // Old Fields
  telephone: string;
  latitude: number;
  longitude: number;
  // New Fields
  price: grossPrice;
  main_image_url: string;
  images: string[];
  important_information: string;
  provider: string;
  thumbnail_image: string[];
  room_quotes: RoomQuote[];
  facilities: string[];
};

export type PropertyRoomDetails = {
  id: number;
  giata: number;
  name: string;
  accommodation_type: string;
  rating: string;
  city: string;
  neighborhood: string;
  country_name: string;
  address_line_1: string;
  address_line_2: string;
  supplier: string;
  amenities: Array<Amenity>;
  images: Array<PropertyImage>;
  rooms: Array<Room>;
  price: grossPrice;
  free_cancellation: boolean;
  instant_confirmation: boolean;
  advance_payment: boolean;
  featured: boolean;
  reserve_now_pay_later: boolean;
  provider: string;
  prop_key: string;

  // New Fields
};

export type GetPropertiesPayload = {
  checkin: string;
  checkout: string;
  rooms_occupancy: Array<RoomOccupancy>;
  filters: any;
  id: string;
  type: HotelLocationType;
  page?: number;
  loadMoreProperties?: boolean;
};

export type GetPropertyDetailsPayload = {
  checkin: string;
  checkout: string;
  rooms_occupancy: Array<RoomOccupancy>;
  id: string;
};

type BookingRoom = {
  adults: number;
  children: number;
  room_type: string;
  id: number;
};

export type InitiatePropertyBookingType = {
  quote_id: string;
};

export type ValidateWebReferenceResponse = {
  booking_id: null | number;
  booking_start_date: string;
  booking_type: string;
  cancellation_policies: Array<CancellationPolicy>;
  checkin: string;
  checkout: string;
  contact_details: null;
  gross_fare: number;
  hotel_type: string;
  id: number;
  lead_guest: number;
  meta: object;
  number_of_guest: number;
  number_of_rooms: number;
  order_new: null | string;
  prop: Property;
  provider: string;
  rooms: Array<BookingRoom>;
  rules_json: Array<string>;
  search_query: HotelSearchQuery;
  tax: number;
  total_fare: number;
  web_reference: string;
};

type BookingLeadGuest = {
  id: number;
  title: string;
  first_name: string;
  last_name: string;
};

type BookingContactDetails = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
};

type HotelBooking = {
  id: number;
  hotel_type: string;
  provider: string;
  booking_id: number | null;
  web_reference: string;
  number_of_guest: number;
  number_of_rooms: number;
  checkin: string;
  checkout: string;
  rules_json: Array<any>;
  gross_fare: number;
  total_fare: number;
  tax: number;
  rooms: Array<BookingRoom>;
  lead_guest: BookingLeadGuest;
  contact_details: BookingContactDetails;
  prop: Property;
  meta: object;
  search_query: HotelSearchQuery;
  cancellation_policies: Array<CancellationPolicy>;
  booking_type: string;
  order_new: number;
  booking_start_date: string;
};

export type BookResponse = {
  order_id: number;
  uuid: string;
  status: string;
  discount: number;
  order_total: number;
  voucher_json: object;
  bookings: Array<HotelBooking>;
  agent: string | null;
  transactions: Array<any>;
  order_expiry: Date;
  parent_order_uuid: null | number;
  public_id: string;
  ag_booking_id: number;
};

export type RoomQuote = {
  stay_quote_id: string;
  room_type: string;
  currency_code: string;
  total_cost: number;
  non_refundable: boolean;
  issue_now_pay_later: boolean;
  ag_payment_date: string;
  supplier?: string;
  meal_board_basis:
    | "Breakfast"
    | "Room Only"
    | "Half Board"
    | "BED AND BREAKFAST";
  cancellation_terms: RoomCancellationTerm[];
  default_currency_code: string;
  default_currency_total_cost: number;
  net_price?: number;
  expires_at?: Date | null;
  check_out?: string;
  check_in?: string;
  thumbnail_url?: string;
  other_inclusions?: string[];
  gross_price: grossPrice;
};

export type CancellationTerm = {
  start_date: string;
  end_date: string;
  cancellation_charge: number;
};

export type HotelPropertyResponse = {
  hotels: Array<Property>;
  filters: any;
  current: number;
  has_previous: boolean;
  has_next: boolean;
  searched_property_not_found: boolean | null;
};

export type PropertySelectedFilters = {
  priceRange: Array<number>;
  propertyRating: Array<string>;
  bookingPolicy: Array<string>;
  supplier: Array<string>;
};

export interface FlightBooking {
  passengers: any[];
  flight_legs: any[];
  flight_sub_provider: string;
  supplier_pnr?: string;
  route_type: ROUTE_TYPE;
  price?: any;
}

export interface Guest {
  type: string;
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  date_of_birth?: string;
}

export interface HotelDetails {
  property: Property;
  guests: Guest[];
  created_at: Date;
  checkin: string;
  checkout: string;
  total_fare: string;
  price: grossPrice;
  number_of_guest: number;
  ag_payment_date: string;
  room: RoomQuote;
}

export enum BookingType {
  HOTEL = "HOTEL",
  FLIGHT = "FLIGHT",
  TOUR = "TOUR",
}

export interface BookingDetailsResponse {
  booking_id: string;
  status: BOOKING_STATUS;
  booking_type: BOOKING_TYPES;
  initiated_booking_expires_at: string;
  confirmed_booking_expires_at: string;
  booking_time: number;
  hotel_details?: HotelDetails;
  flight_details?: FlightBooking;
  tour_details?: TourDetailResponse;
}

export interface HotelTraveler {
  title: string;
  date_of_birth: string;
  first_name: string;
  last_name: string;
  type?: string;
}

export interface UpdateTravelerType {
  index: number;
  key: keyof HotelTraveler;
  value: boolean | string | null;
}

interface BookingCustomer {
  title: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
}

interface GuestPayload {
  title: string;
  first_name: string;
  last_name: string;
  type: string;
  date_of_birth: string;
}

export interface IConfirmBooking {
  booking_id: string;
  booking_customer: BookingCustomer;
  guests: GuestPayload[];
}

export interface IHotelIssue {
  booking_id: string;
  otp: string;
}

export interface ICreateOTP {
  total_mins: number;
}

export interface ISendOTP {
  booking_id: string;
}
