<template>
  <ag-modal
    persistent
    :isOpen="isOpen"
    @close="closeDialog"
    modal-width="450px"
  >
    <template #body>
      <div class="otp-modal">
        <div class="otp-modal__close_btn">
          <MIcon @click="closeDialog" name="m-outlined-cancel" />
        </div>
        <div class="otp-modal__header">
          <MTypography type="headline" class="otp-modal__title">{{
            title
          }}</MTypography>
        </div>
        <div>
          <slot name="component"> </slot>
        </div>
        <div class="otp-modal__body">
          <MTypography type="body" class="otp-modal__instruction">{{
            instruction
          }}</MTypography>
          <div class="otp-input">
            <input
              v-for="(digit, index) in otpDigits"
              :key="index"
              type="text"
              v-model="otpDigits[index]"
              @input="onInput(index)"
              @keydown="handleKeyDown(index, $event)"
              @paste="handlePaste"
              :ref="(el) => { if (el) inputRefs[index] = el as HTMLInputElement }"
              maxlength="1"
              class="otp-input__digit"
              :class="{ 'otp-input__digit--filled': digit }"
              :aria-label="`Digit ${index + 1} of OTP`"
            />
          </div>
          <MTypography
            type="body"
            v-if="error"
            class="otp-modal__error"
            role="alert"
            >{{ error }}</MTypography
          >
          <div class="otp-modal__actions">
            <MButton
              @click="submitOTP"
              variant="primary"
              :disabled="!isOtpComplete || isSubmitLoading"
            >
              Verify OTP
            </MButton>
          </div>
        </div>
      </div>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MButton, MIcon, MTypography } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "OTPInputModal",
  components: { MButton, MTypography, MIcon },
  props: {
    isOpen: Boolean,
    title: {
      type: String,
      default: "OTP Verification",
    },
    instruction: {
      type: String,
      default: "Please enter the 6-digit code sent to your device.",
    },
    isSubmitLoading: Boolean,
    length: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      otpDigits: Array(this.length).fill("") as string[],
      error: "",
      inputRefs: [] as HTMLInputElement[],
    };
  },
  computed: {
    isOtpComplete(): boolean {
      return this.otpDigits.every((digit) => digit !== "");
    },
  },
  methods: {
    saveInputRef(ref: HTMLInputElement, index: number) {
      this.inputRefs[index] = ref;
    },
    onInput(index: number) {
      this.error = "";
      if (this.otpDigits[index] && index < this.length - 1) {
        this.inputRefs[index + 1]?.focus();
      }
    },
    handleKeyDown(index: number, event: KeyboardEvent) {
      if (event.key === "Backspace" && !this.otpDigits[index] && index > 0) {
        this.inputRefs[index - 1]?.focus();
      }
    },
    handlePaste(event: ClipboardEvent) {
      event.preventDefault();
      const pastedText = event.clipboardData?.getData("text/plain") || "";
      const digits = pastedText
        .replace(/\D/g, "")
        .slice(0, this.length)
        .split("");
      this.otpDigits = [
        ...digits,
        ...Array(this.length - digits.length).fill(""),
      ];
      this.$nextTick(() => {
        this.inputRefs[digits.length]?.focus();
      });
    },
    submitOTP() {
      if (this.isOtpComplete) {
        this.$emit("submit", this.otpDigits.join(""));
      } else {
        this.error = `Please enter all ${this.length} digits of the OTP.`;
      }
    },
    closeDialog() {
      this.otpDigits = Array(this.length).fill("");
      this.error = "";
      this.$emit("close");
    },
  },
  mounted() {
    this.inputRefs = this.inputRefs.slice(0, this.length);
  },
  updated() {
    // Ensure inputRefs array is always up to date
    this.inputRefs = this.inputRefs.slice(0, this.length);
  },
});
</script>

<style lang="scss" scoped>
.otp-modal {
  padding: 1rem;
  background-color: var(--m-light-color);
  border-radius: 8px;

  &__close_btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }

  &__header {
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--m-secondary-color);
    margin: 0;
    text-align: center;
  }

  &__instruction {
    text-align: center;
    color: var(--m-label-color);
    margin-bottom: 1.5rem;
  }

  &__error {
    color: var(--m-error-color);
    text-align: center;
    margin-top: 1rem;
    font-size: 0.875rem;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
}

.otp-input {
  display: flex;
  justify-content: center;
  gap: 0.75rem;

  &__digit {
    width: 3rem;
    height: 3.5rem;
    font-size: 1.5rem;
    border: 2px solid var(--m-border-color);
    border-radius: 8px;
    text-align: center;
    transition: all 0.3s ease;
    background-color: var(--m-container-color);

    &:focus {
      border-color: var(--m-success-color);
      box-shadow: 0 0 0 3px rgba(74, 226, 150, 0.3);
      outline: none;
    }

    &--filled {
      border-color: var(--m-success-color);
      background-color: var(--m-light-colo);
    }
  }
}

@media (max-width: 480px) {
  .otp-input__digit {
    width: 2.5rem;
    height: 3rem;
    font-size: 1.25rem;
  }
}
</style>
<style lang="scss">
.otp-modal {
  &__close_btn {
    svg {
      cursor: pointer;
      fill: var(--m-error-color) !important;
    }
  }
}
</style>
