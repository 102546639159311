<template>
  <MCard class="agent-management-heading">
    <div class="d-flex justify-content-between align-items-center">
      <AgIconInfoBar
        test-id=""
        title="Agent Management"
        class="section-title"
        mPrepandIcon="m-agent-management"
      />
      <div>
        <MButton variant="filled" @click="onAddNewAgentBtnClick"
          >Add New Agent</MButton
        >
      </div>
    </div>
  </MCard>
  <MCard>
    <MDataTable
      :headers="headers"
      :data="agentsList"
      :item-per-page="30"
      :is-api-paginated="false"
      :has-search="true"
      :is-loading="isFetchingOrganizationAgents"
    >
      <template #pricing="{ item }">
        <MButton
          :disabled="isOwnerUser(item.agent_type)"
          @click="onClickManagePricing(item.id)"
        >
          {{ item.pricing }}
        </MButton>
      </template>

      <template #action="{ item }">
        <MButton
          :disabled="isOwnerUser(item.agent_type)"
          @click="onClickUpdatePermissions(item.id)"
        >
          {{ item.action }}
        </MButton>
      </template>
      <template #remove="{ item }">
        <MFabButton
          class="delete-icon"
          icon="m-delete"
          :disabled="!isEnableUserDeletion(item?.agent_type)"
          @click="onDeleteAgentRequest(item)"
        />
      </template>
    </MDataTable>
  </MCard>
  <AddAgentModal
    :is-open="isAddAgentDialogOpen"
    @handleClose="onCloseAddAgentDialog"
    @onSubmit="onAddEmail"
  />
  <MDialog title="Delete Agent" v-model="isDeleteAgentDialogOpen" hide-header>
    <template #content>
      <div class="delete-agent-alert">
        <MIcon
          class="delete-agent-alert-icon"
          name="m-info"
          width="48"
          height="48"
        />
        <div class="delete-agent-alert-title">Delete Agent</div>
        <div class="delete-agent-alert-subtitle">
          Are you sure you want to delete
          {{ selectedAgent?.email }}
        </div>
      </div>
    </template>
    <template #actions>
      <MButton
        class="delete-agent-alert-action"
        @click="deleteAgent"
        :disabled="$store.getters.isDeletingAgentFromOrganization"
      >
        Delete
      </MButton>
      <MButton
        class="delete-agent-alert-action"
        @click="closeAgentDeletionModal"
      >
        Cancel
      </MButton>
    </template>
  </MDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import { AGENT_SUB_ROLES } from "@/ag-portal-common/enums/AGENT_SUB_ROLES";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { MDataTable } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { IAgentDetail } from "@/ag-portal-common/interfaces/agent.interface";
import { Organization, USER_ROLES } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import UTILS from "@/ag-portal-common/utils";
import AddAgentModal from "@/modules/Organization/components/AddAgentModal.vue";
import { AddAgentDto } from "@/modules/Organization/dtos/agent.dto";
import analyticsService from "@/analytic.service";
import { AGENT_ANALYTICS_EVENTS } from "@/modules/Agent/constants/analyticsEvents";
import { MDialog } from "@aeroglobe/ag-core-ui";

interface AgentList {
  id: string | number | null;
  name: string | undefined;
  email: string | null;
  is_active: string;
  agent_type: AGENT_SUB_ROLES;
  pricing: string;
  action: string;
}

export default defineComponent({
  name: "AgentManagement",
  components: {
    MDataTable,
    AddAgentModal,
    MDialog,
  },
  data() {
    return {
      isAddAgentDialogOpen: false,
      AGENT_SUB_ROLES,
      isDeleteAgentDialogOpen: false,
      selectedAgent: null as AgentList | null,
      headers: [
        {
          title: "Name",
          value: "name",
          key: "name",
          sortable: true,
        },
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
        },
        {
          title: "Agent Type",
          value: "agent_type",
          key: "agent_type",
          sortable: true,
        },
        {
          title: "Active",
          value: "is_active",
          key: "is_active",
          sortable: true,
        },
        {
          title: "Flight Pricing",
          value: "pricing",
          key: "pricing",
          sortable: false,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
        {
          title: "Remove",
          value: "remove",
          key: "remove",
          sortable: false,
        },
      ] as MDataTableHeader[],
    };
  },
  computed: {
    PATH(): typeof PATH {
      return PATH;
    },
    isUserAgent() {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    isSuperUser() {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER);
    },
    agentsList(): AgentList[] {
      const agentsList = this.$store.getters
        .organizationAgents as IAgentDetail[];

      return agentsList.map((item) => ({
        id: item.agent_id || item.user.id,
        name: item.user.full_name,
        email: item.user.email,
        is_active: item.user.is_active ? "Active" : "In-Active",
        agent_type: item.user.role_sub_type,
        pricing: "Manage Pricing",
        action: "Update Permissions",
      }));
    },
    isFetchingOrganizationAgents(): boolean {
      return this.$store.getters.isFetchingOrganizationAgents;
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
  },
  beforeMount() {
    if (this.organization) {
      const id = this.organization.organization_id;

      this.$store.dispatch("getOrganizationAgents", id);
    }
  },
  methods: {
    genTestId,
    deleteAgent() {
      this.$store.dispatch("deleteAgentFromOrganization", {
        agentId: this.selectedAgent?.id,
        callback: this.closeAgentDeletionModal,
      });
    },
    isEnableUserDeletion(agentSubRole: AGENT_SUB_ROLES) {
      if (this.isSuperUser && agentSubRole === AGENT_SUB_ROLES.OWNER) {
        return true;
      }
      if (this.isUserAgent && agentSubRole !== AGENT_SUB_ROLES.OWNER) {
        return true;
      }
      return false;
    },
    onDeleteAgentRequest(agent: any) {
      this.selectedAgent = agent;
      this.isDeleteAgentDialogOpen = true;
    },
    closeAgentDeletionModal() {
      this.isDeleteAgentDialogOpen = false;
      this.onCloseAddAgentDialog();
    },
    showAddNewAgentBtn() {
      return this.isUserAgent || this.isSuperUser;
    },
    onAddEmail(payload: AddAgentDto) {
      this.$store.dispatch("addAgentInOrganization", {
        body: payload,
        orgId: this.organization?.organization_id,
        callback: this.onCloseAddAgentDialog,
      });

      const analyticsPayload = {
        "organisation-id": this.organization?.organization_id,
        "user-email": payload.email,
        "user-fullname": payload.full_name,
        "user-role": payload.role,
      };

      analyticsService.logActionEvent(
        AGENT_ANALYTICS_EVENTS.AGENT_ADD,
        analyticsPayload
      );
    },
    onCloseAddAgentDialog() {
      this.isAddAgentDialogOpen = false;
      const id = this.organization?.organization_id;

      this.$store.dispatch("getOrganizationAgents", id);
    },
    onClickUpdatePermissions(agentId: string): void {
      this.$router.push(`${PATH.AGENT_PERMISSIONS}/${agentId}`);
    },
    onClickManagePricing(agentId: string): void {
      this.$router.push(`${PATH.AGENT_PRICING}/${agentId}`);
    },
    isOwnerUser(userType: string): boolean {
      return userType === AGENT_SUB_ROLES.OWNER;
    },
    onAddNewAgentBtnClick() {
      this.isAddAgentDialogOpen = true;
    },
  },
});
</script>

<style lang="css" scoped>
.agent-management-heading {
  margin-bottom: 10px;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
.delete-agent-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;

  padding: 16px;
}

.delete-agent-alert .delete-agent-alert-icon {
  width: 48px;
  height: 48px;

  color: var(--m-error-color);
}
.delete-agent-alert .delete-agent-alert-title {
  font-size: 24px;
  font-weight: 500;
}
.delete-agent-alert .delete-agent-alert-subtitle {
  color: var(--m-label-color);
}
.delete-agent-alert-action {
  background-color: var(--m-error-color);
}
</style>
