<template>
  <ag-loader v-if="$store.state.hotelModule.isLoading" />
  <ag-row v-else>
    <ag-column cols="12" sm="12" md="12" lg="9">
      <AgPropertyReview
        test-id=""
        :rating="bookingDetails?.property?.rating"
        :hotel-thumb="formatUrl(bookingDetails?.property.main_image_url || '')"
        :hotel-name="bookingDetails?.property?.name"
        :room-quote="roomQoute(bookingDetails?.room?.room_type)"
        :checkin-date="renderDate(bookingDetails?.checkin)"
        :checkout-date="renderDate(bookingDetails?.checkout)"
        :no-of-night="
          getNights(bookingDetails?.checkin, bookingDetails?.checkout)
        "
        :address="bookingDetails?.property.address1"
        :location="renderLocation(bookingDetails?.property)"
      >
        <template #hotelImages>
          <AgFancyBox class="d-flex">
            <ag-div>
              <ag-div class="bed-and-breakfast-container">
                {{ bookingDetails?.room?.meal_board_basis }}
              </ag-div>
              <agdiv style="display: flex; flex-wrap: wrap">
                <FaciltyChip
                  v-for="(item, index) in displayedFacilities"
                  :key="index"
                  :name="item.value"
                  :icon-name="item.Icon"
                />
              </agdiv>
            </ag-div>

            <AgDiv v-for="(item, index) in propertyImages" :key="index">
              <a data-fancybox="gallery" :href="item">
                <AgImage
                  test-id=""
                  :src="item"
                  class="mr-10"
                  width="52px"
                  height="52px"
                />
              </a>
            </AgDiv>
          </AgFancyBox>
        </template>
      </AgPropertyReview>

      <!-- Lead Contact Box -->
      <MAccordion :initial-open="true">
        <template #title>
          <div class="accordion-title-container">
            <div class="headline-container">
              <MIcon
                class="hotel-form-accordion-title-traveler-icon"
                name="m-contact"
              />
              <MTypography class="headline" type="headline"
                >Contact Details
              </MTypography>
            </div>
            <hr />
            <div class="title-container">
              <MTypography class="margin_bottom_0" type="title"
                >Lead Traveler
              </MTypography>
            </div>
          </div>
        </template>
        <template #content>
          <AgRow test-id="" class="padding_top_20">
            <AgColumn test-id="" md="12" lg="7" cols="12">
              <AgRow test-id="">
                <AgColumn test-id="" md="12" lg="9">
                  <AgPhoneField
                    test-id=""
                    default-country-code="PK"
                    info-message="Please input the traveler’s
                        number here to receive flight updates"
                    @update-value="handleMobileFieldChange"
                  />
                  <MTextfield
                    v-model:inputValue="email"
                    type="email"
                    label="Email"
                    placeholder="Enter your email"
                    name="email"
                    :hasError="renderEmailError"
                    :errorMessage="renderEmailError"
                  />
                </AgColumn>
              </AgRow>
            </AgColumn>
            <AgColumn test-id="" md="12" lg="5" cols="12">
              <!-- Side Box -->
              <AgTravelDetail
                test-id=""
                :items="{
                  'Mobile No': $store.state.hotelModule.mobileNumber,
                  Email: $store.state.hotelModule.email,
                }"
                :phone="$store.state.hotelModule.mobileNumber"
                :email="$store.state.hotelModule.email"
              >
                <template #headingArea>
                  <AgHeading
                    variant="h2"
                    title="Contact Details"
                    class="margin_bottom_5"
                  />
                </template>
              </AgTravelDetail>
            </AgColumn>
          </AgRow>
        </template>
      </MAccordion>

      <TravelerCard
        v-for="(traveler, index) in travelers"
        :traveler="traveler"
        :errors="errors"
        :index="index"
        :key="index"
      />

      <AgDiv test-id="" class="text-right">
        <AGButton
          test-id=""
          type="button"
          @click="handleConfirmBooking"
          :is-loading="isConfirmBookingLoading"
          >Confirm Booking
        </AGButton>
      </AgDiv>
    </ag-column>
    <ag-column cols="12" sm="12" md="12" lg="3">
      <!-- Payment Policy -->
      <PaymentPolicy
        v-if="bookingDetails?.ag_payment_date"
        :ag-payment-date="bookingDetails?.ag_payment_date"
      />

      <!-- Cancellation Policy -->
      <CancellationPolicy
        :cancellation-terms="bookingDetails?.room?.cancellation_terms"
      />
      <!-- Price Summary -->
      <PriceSummary
        :propert-name="bookingDetails?.property?.name"
        :total-currency="bookingDetails?.price.value"
        :currency-code="bookingDetails?.price.currency"
      />
    </ag-column>
  </ag-row>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ValidationError } from "yup";
import { differenceInDays, format } from "date-fns";
import _ from "lodash";

import { getHotelFacility } from "../utils/hotelFacilities";
import FaciltyChip from "../components/FacilityChip.vue";
import {
  formatImageUrl,
  formatStringToRoutePath,
  getCurrencyFormatter,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";

import { PhoneFieldValue } from "@aeroglobe/ag-core-ui/dist/src/components/atoms/AgPhoneField/AgPhoneField.vue";
import {
  HotelDetails,
  HotelTraveler,
  Property,
  PropertyDetail,
} from "@/ag-portal-common/types/hotel";
import { getTravelerTypeName, TravelerTypes } from "../constants/travelerTypes";
import TravelerCard from "../components/TravelerCard.vue";
import bookingFormValidationSchema from "../validations/bookingFormValidation";
import CancellationPolicy from "../components/CancellationPolicy.vue";
import PriceSummary from "../components/PriceSummary.vue";
import analyticsService from "@/analytic.service";
import { HOTEL_ANALYTICS_EVENTS } from "../constants/analyticsEvents";
import PaymentPolicy from "@/modules/Hotel/components/PaymentPolicy.vue";

export default defineComponent({
  name: "HotelPropertyBookingForm",
  watch: {
    email(newValue) {
      this.handleEmailFieldChange(newValue);
    },
  },
  data(): { errors: any; email: string } {
    return {
      errors: {},
      email: "",
    };
  },
  setup() {
    const otherInclusions = ref([]);
    const rateComments = ref({
      comments: "",
      MandatoryTax: "",
    });
    const storedData = localStorage.getItem("grn");
    if (storedData) {
      const hotelData = JSON.parse(storedData);
      if (hotelData.data.room_quotes && hotelData.data.room_quotes.length > 0) {
        const firstRoomQuote = hotelData.data.room_quotes[0];
        otherInclusions.value = firstRoomQuote?.other_inclusions ?? [];
        rateComments.value = {
          comments: firstRoomQuote.rate_comments?.comments ?? "",
          MandatoryTax: firstRoomQuote?.rate_comments?.MandatoryTax || "",
        };
      }
    }

    return {
      otherInclusions,
      rateComments,
    };
  },
  components: {
    PaymentPolicy,
    TravelerCard,
    CancellationPolicy,
    PriceSummary,
    FaciltyChip,
  },
  methods: {
    renderLocation(propertyDetails: Property) {
      const city = propertyDetails?.city ?? propertyDetails?.city_name;
      const country = propertyDetails?.country ?? propertyDetails?.country;
      return city + ", " + country;
    },
    renderDate(date: string) {
      return date ? format(new Date(date), "EEEE, MMM d, yyyy") : "";
    },
    getNights(checkin: string, checkout: string) {
      const nights = checkin
        ? differenceInDays(new Date(checkout), new Date(checkin))
        : 1;
      return `${nights} Night${nights > 1 ? "s" : ""} Stay`;
    },
    formatUrl(url: string) {
      return formatImageUrl(url);
    },
    roomQoute(roomType: string) {
      return `${roomType} - 1 ROOM`;
    },
    getPrice(amount: number | string) {
      return getCurrencyFormatter().format(Number(amount));
    },
    formatDate(date: string) {
      return date ? format(new Date(date), "MMM dd yyyy") : "";
    },
    formatTravelers() {
      const { adult, child } = this.$route.query;
      const child_count = child ? (child as string).split(",").length : 0;

      const travelerInitialValues = {
        title: "",
        date_of_birth: null,
        first_name: "",
        last_name: "",
      };

      const travelers = [
        ...Array.from(Array(Number(adult)).keys()).map(() => ({
          ...travelerInitialValues,
          type: TravelerTypes.ADULT,
        })),
        ...Array.from(Array(child_count).keys()).map(() => ({
          ...travelerInitialValues,
          type: TravelerTypes.CHILD,
        })),
      ];
      this.$store.commit("saveTravelers", travelers);
    },
    handleMobileFieldChange(value: PhoneFieldValue) {
      this.$store.commit("updateMobileNumber", value.e164);
    },
    handleEmailFieldChange(email: string) {
      this.$store.commit("updateEmail", email);
    },
    handleSuccessBooking(id: string) {
      this.$router.push(
        formatStringToRoutePath(PATH.HOTEL_BOOKING_DETAILS, { id })
      );
    },
    async handleConfirmBooking() {
      try {
        const booking_id = this.$route.params.id as string;
        const email = this.$store.state.hotelModule.email;
        const guests = this.$store.getters.travelers || [];

        await bookingFormValidationSchema.validate(
          {
            guests,
            mobile_number: this.$store.state.hotelModule.mobileNumber,
            email,
          },
          { abortEarly: false }
        );

        const mobile_number =
          this.$store.state.hotelModule.mobileNumber?.replace(
            /^0(\d{3})\s?(\d{7})$/,
            "+92$1$2"
          );
        const { ...rest } = guests[0] as HotelTraveler;
        const payload = {
          booking_id,
          booking_customer: { ...rest, phone: mobile_number, email },
          guests: guests.map((x: HotelTraveler) => {
            const date = format(new Date(x.date_of_birth), "yyyy-MM-dd");
            return {
              ...x,
              type: getTravelerTypeName(x?.type as string),
              date_of_birth: date,
            };
          }),
        };
        analyticsService.logActionEvent(
          HOTEL_ANALYTICS_EVENTS.AG_HOTEL_PROPERTY_BOOKING,
          payload
        );
        this.$store.dispatch("confirmHotelBooking", {
          payload,
          successCallback: this.handleSuccessBooking,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const err = yupValidationErrorAsSchema(ex);
          this.errors = err;
        }
      }
    },
  },
  computed: {
    displayedFacilities() {
      const bookingDetails = this.$store.getters.bookingDetails;
      const formattedFacilities = bookingDetails?.property?.facilities?.map(
        (item: any) => {
          return getHotelFacility(item);
        }
      );
      return formattedFacilities;
    },
    renderMobileNumberError(): string {
      return this.errors?.mobile_number as string;
    },
    renderEmailError(): string {
      return this.errors?.email as string;
    },
    isConfirmBookingLoading(): boolean {
      return this.$store.getters.isConfirmBookindLoading;
    },
    bookingDetails(): HotelDetails {
      return this.$store.getters.bookingDetails;
    },
    getTravelersCount(): string {
      const { adult, child } = this.$route.query;

      const child_count = child ? (child as string).split(",").length : 0;
      const tolalCount = Number(adult) + child_count;

      return `Traveler${tolalCount > 1 ? "s" : ""} ${tolalCount}, Room 1`;
    },
    travelers() {
      return this.$store.getters.travelers;
    },
    propertyImages() {
      const propertyDetails = this.$store.getters
        .propertyDetail as PropertyDetail;
      const images = _.cloneDeep(propertyDetails?.images) || [];
      return images?.splice(0, 5);
    },
  },
  mounted() {
    const bookingId = this.$route.params?.id;
    this.$store.dispatch("fetchBookingDetails", bookingId);
    this.formatTravelers();
  },
});
</script>

<style lang="scss" scoped>
.bed-and-breakfast-container {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  background: var(--green-color);
  color: #fff;
  font-weight: BOLD;
  padding: 5px 20px;
  border-radius: 8px;
  margin-top: 20px;
}
</style>

<style lang="css" scoped>
.accordion-title-container {
  width: 100%;
  .headline-container {
    display: flex;
    align-items: center;
    .headline {
      margin-left: 10px;
      .required-error-text {
        font-size: 16px;
        font-weight: 800;
        color: var(--m-error-color);
      }
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 18px;
  }

  .error-icon {
    color: var(--error-color);
  }
}

hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid var(--neutral-300);
}
</style>

<style>
.hotel-form-accordion-title-traveler-icon {
  svg {
    width: 35px;
    height: 30px;
    fill: var(--m-primary-color) !important;
  }
}
</style>
