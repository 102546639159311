<template>
  <MCard class="organizations-heading">
    <div class="d-flex justify-content-between align-items-center">
      <AgIconInfoBar
        test-id=""
        title="Organizations"
        class="section-title"
        mPrepandIcon="m-organisations"
      />
      <div>
        <MButton @click="onClickCreateNew" type="filled">
          Create new organization
        </MButton>
      </div>
    </div>
  </MCard>
  <MCard>
    <MDataTable
      :headers="headers"
      :is-loading="$store.getters.isFetchingOrganizations"
      :data="transformTableData"
      :items-per-page="30"
      :has-search="true"
      :simpleDataTable="true"
    >
      <template #public_id="{ item }">
        <router-link :to="`${PATH.ORGANIZATION}/${item.id}`">
          <MTypography
            class="description td-full-width organization-id"
            type="body"
            >{{ item.public_id }}</MTypography
          >
        </router-link>
      </template>
      <template #status="{ item }">
        <div class="status-container">
          <MChip
            class="pricing-chip"
            :variant="organizationStatusClass(item.status)"
            >{{ item.status }}</MChip
          >
        </div>
      </template>

      <template #action="{ item }">
        <MButton
          type="filled"
          @click="onClickUpdate($event, item.id)"
          :testId="
            genTestId(ELEMENT_TYPES.BUTTON, `organization-${item.id}-action`)
          "
          >{{ item.action }}
        </MButton>
      </template>
    </MDataTable>
  </MCard>
</template>

<script lang="ts">
import { PATH } from "@/ag-portal-common/constants/path";
import { defineComponent } from "vue";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import {
  formatStringToRoutePath,
  genTestId,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { ORGANIZATION_STATUS_MAPPING, ORGANIZATION_STATUS } from "../constants";
import { MButton, MDataTable, MChip, MTypography } from "@aeroglobe/ag-core-ui";
import { ChipVariant } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { MDataTableHeader } from "@/ag-portal-common/types";

export default defineComponent({
  name: "OrganizationListView",
  components: {
    MDataTable,
    MButton,
    MChip,
  },
  data() {
    return {
      search: "",
      isMobile: false,
      ELEMENT_TYPES,
      ORGANIZATION_STATUS_MAPPING,
      ORGANIZATION_STATUS,
      headers: [
        {
          key: "public_id",
          title: "Public Id",
          value: "public_id",
        },
        {
          key: "name",
          title: "Name",
          value: "name",
        },
        {
          title: "Sector Name",
          value: "sector_name",
          key: "sector_name",
          sortable: true,
        },
        {
          title: "PIA Provider",
          value: "pia_provider",
          key: "pia_provider",
          sortable: true,
        },
        {
          title: "Phone",
          value: "phone",
          key: "phone",
          sortable: true,
        },
        {
          title: "Email",
          value: "email",
          key: "email",
          sortable: true,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: true,
        },
        {
          title: "Action",
          value: "action",
          key: "action",
          sortable: false,
        },
      ] as MDataTableHeader[],
    };
  },
  beforeMount() {
    this.fetchOrganizations();
  },
  methods: {
    genTestId,
    fetchOrganizations() {
      this.$store.dispatch("fetchOrganizations");
    },
    onClickCreateNew() {
      this.$router.push(PATH.CREATE_ORGANIZATION);
    },
    onClickUpdate(e: any, id: string) {
      e.stopImmediatePropagation();
      this.$router.push(
        formatStringToRoutePath(PATH.UPDATE_ORGANIZATION, { id })
      );
    },
    organizationStatusClass(status: ORGANIZATION_STATUS): ChipVariant {
      return status === ORGANIZATION_STATUS.ACTIVE ? "success" : "error";
    },
  },
  computed: {
    PATH() {
      return PATH;
    },
    transformTableData() {
      return this.$store.getters.organizations.map((item: IOrganization) => ({
        id: item.public_id,
        public_id: item.default_financial_profile?.platform_id
          ? `${item.public_id} (${item.default_financial_profile?.platform_id})`
          : `${item.public_id} (-)`,
        name: item.name,
        sector_name: item.sector,
        pia_provider: item.pia_provider,
        phone: item.contact_person_phone ? item.contact_person_phone : "-",
        email: item.contact_person_email ? item.contact_person_email : "-",
        status: item.status,
        action: "Update",
      }));
    },
  },
});
</script>

<style scoped>
.organization-id {
  color: var(--green-color);
}
.min-width-200 {
  min-width: 200px;
}
.status-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.text-align-center {
  text-align: center;
}
.create-org-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.organizations-heading {
  margin-bottom: 10px;
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
