<template>
  <div class="flight-hero-img"></div>
  <FlightSearchComponent />

  <MCard
    class="flight-search-recent-search-card"
    v-if="onewayAndReturnHasItemsToShow"
  >
    <span class="title">Your recent searches</span>
    <div class="items-container">
      <RecentSearchBox
        v-for="(item, index) in onewayAndReturnSearchesItems"
        :key="index"
        :origin="item?.origin"
        @click="handleContineSearchClick(item)"
        :destination="item?.destination"
        :trip-type="renderFlightType(item?.route_type)"
        :cabin-class="renderCabinClass(item?.cabin_class)"
        :travelers="renderTotalTravelersCount(item?.traveler_count)"
        :itinerary-details="
          renderItineraryDetails(
            item?.departure_date,
            item?.return_date,
            item?.path,
            item?.route_type
          )
        "
      />
    </div>
  </MCard>

  <MCard
    class="flight-search-recent-search-card"
    v-if="multicityHasItemsToShow"
  >
    <span class="title">Multicity</span>
    <div class="items-container">
      <RecentSearchBox
        v-for="(item, index) in multicitySearchesItems"
        :key="index"
        :origin="item?.origin"
        @click="handleContineSearchClick(item)"
        :destination="item?.destination"
        :trip-type="renderFlightType(item?.route_type)"
        :cabin-class="renderCabinClass(item?.cabin_class)"
        :travelers="renderTotalTravelersCount(item?.traveler_count)"
        :itinerary-details="
          renderItineraryDetails(
            item?.departure_date,
            item?.return_date,
            item?.path,
            item?.route_type
          )
        "
      />
    </div>
  </MCard>
  <div v-else class="empty-space-for-flight-search-landing-page"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MCard } from "@aeroglobe/ag-core-ui";
import _ from "lodash";
import FlightSearchComponent from "@/ag-flight-components/components/FlightSearch/index.vue";

import { FORMAT_DD_MM_YYYY_WITH_SPACE } from "@/ag-portal-common/constants/dateTimeFormats";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import {
  ROUTE_TYPE,
  ROUTE_TYPE_MAPPING,
} from "@/ag-flight-components/enums/route_type";
import RecentSearchBox from "@/ag-flight-components/components/FlightSearch/RecentSearchBox.vue";
import { TravelerCount } from "@/ag-flight-components/types";
import { format } from "date-fns";
import { formatMultiCityQueryParamToArray } from "@/ag-flight-components/utils";
import getLocalbaseInstance from "@/ag-flight-components/constants/localbase";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "FlightSearch",
  components: {
    FlightSearchComponent,
    MCard,
    RecentSearchBox,
  },
  data(): { recentSearchItems: any[]; searchItemsLoading: boolean } {
    return {
      recentSearchItems: [],
      searchItemsLoading: false,
    };
  },
  computed: {
    renderEmptyMessage(): boolean {
      return !this.searchItemsLoading && !this.recentSearchItems.length;
    },
    onewayAndReturnHasItemsToShow(): boolean {
      return (
        !this.searchItemsLoading &&
        this.onewayAndReturnSearchesItems?.length > 0
      );
    },
    multicityHasItemsToShow(): boolean {
      return (
        !this.searchItemsLoading && this.multicitySearchesItems?.length > 0
      );
    },
    onewayAndReturnSearchesItems(): Array<any> {
      return (
        this.recentSearchItems
          ?.filter((item: any) => item?.route_type !== ROUTE_TYPE.MULTI_CITY)
          ?.slice(0, 8) || []
      );
    },
    multicitySearchesItems(): Array<any> {
      return (
        this.recentSearchItems
          ?.filter((item: any) => item?.route_type === ROUTE_TYPE.MULTI_CITY)
          ?.slice(0, 8) || []
      );
    },
  },
  methods: {
    async continueSearchItems() {
      const db = getLocalbaseInstance();
      this.searchItemsLoading = true;
      try {
        const response = await db.collection("flights").get();
        const uniqueData = new Set();
        // const currentDate = new Date();
        const filteredAndUniqueData = response
          .sort((a: any, b: any) => b?.timestamp - a?.timestamp)
          .filter((item: any) => {
            const key = item.path;
            if (!uniqueData.has(key)) {
              uniqueData.add(key);
              return true;
            }
            return false;
          });
        const today = new Date();
        const todayString = today.toISOString().split("T")[0];

        const filteredFlights = filteredAndUniqueData.filter((flight: any) => {
          const departureDate = new Date(flight.departure_date);
          const departureDateString = departureDate.toISOString().split("T")[0];
          return departureDateString >= todayString;
        });

        await db.collection("flights").set(filteredFlights);
        this.recentSearchItems = filteredFlights as any[];
      } catch (e) {
        console.log("Error", e);
      } finally {
        this.searchItemsLoading = false;
      }
    },
    formatDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MM_YYYY_WITH_SPACE);
    },
    renderFlightType(flightType: ROUTE_TYPE) {
      return ROUTE_TYPE_MAPPING[flightType];
    },
    handleContineSearchClick(item: any) {
      const updatePath = UTILS.flightSearchResultsPath();
      const path = item?.path as string;

      this.$router.push(updatePath + path.slice(path.indexOf("?")));
    },
    renderCabinClass(cabinClass: string) {
      return _.capitalize(cabinClass);
    },
    renderTotalTravelersCount(travelers: TravelerCount): number {
      const adult_count = Number(travelers?.adult_count ?? 0);
      const child_count = Number(travelers?.child_count ?? 0);
      const infant_count = Number(travelers?.infant_count ?? 0);
      return adult_count + child_count + infant_count;
    },
    renderItineraryDetails(
      departure_date: Date,
      return_date: Date | null,
      path: string,
      routeType: ROUTE_TYPE
    ) {
      const itineraryDateFormat = "EEE, MMMM d";
      if (routeType == ROUTE_TYPE.ONEWAY) {
        return format(new Date(departure_date), itineraryDateFormat);
      } else if (routeType == ROUTE_TYPE.RETURN) {
        return `${format(
          new Date(departure_date),
          itineraryDateFormat
        )} - ${format(new Date(return_date as Date), itineraryDateFormat)}`;
      } else if (routeType == ROUTE_TYPE.MULTI_CITY) {
        const baseURL = window.location.origin;
        const urlObj = new URL(path, baseURL);
        const params = new URLSearchParams(urlObj.search);
        const trips = params.get("trips");
        const multiCityParamsArray =
          formatMultiCityQueryParamToArray(trips as string) || [];
        const firstDepartureDate = multiCityParamsArray[0]?.departure_date;
        const lastDepartureDate =
          multiCityParamsArray[multiCityParamsArray.length - 1]?.departure_date;

        return `${format(
          new Date(firstDepartureDate),
          itineraryDateFormat
        )} ... ${format(new Date(lastDepartureDate), itineraryDateFormat)}`;
      }
    },
  },
  async mounted() {
    await this.continueSearchItems();
  },
  beforeMount() {
    this.$store.dispatch("fetchAirports", { location: "Pakistan" });
    this.$store.dispatch("fetchMultiCityAirports", { location: "Pakistan" });
  },
});
</script>

<style scoped lang="scss">
.scroll {
  overflow-y: auto;
}
.flight-search-recent-search-card {
  margin-top: 25px;
  .title {
    font-weight: 500;
    font-size: 22px;
  }
}

.items-container {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 20px;
}

.items-container > * {
  flex: 1 1 calc(20% - 10px);
  max-width: calc(20% - 10px);
  box-sizing: border-box;
}

@media (max-width: 1472px) {
  .items-container > * {
    flex: 1 1 calc(25% - 14px);
    max-width: calc(25% - 14px);
  }
}
@media (max-width: 1269px) {
  .items-container > * {
    flex: 1 1 calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
}
@media (max-width: 1040px) {
  .items-container > * {
    flex: 1 1 calc(50% - 14px);
    max-width: calc(50% - 14px);
  }
}
@media (max-width: 1040px) {
  .items-container > * {
    flex: 1 1 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
}
.heading {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.empty-space-for-flight-search-landing-page {
  margin-bottom: 150px;
}

.flight-hero-img {
  background-image: url("@/assets/FlightArtWork.svg");
  width: 100%;
  height: 220px;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .flight-hero-img {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .flight-hero-img {
    height: 100px;
  }
}
</style>
