import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import {
  ORGANIZATION_API_PATH,
  WHATSAPP_API_PATH,
} from "@/modules/Organization/constants";
import { IOrganization } from "@/ag-portal-common/interfaces/organization.interface";
import {
  AddWhatsappMemberDto,
  ContentUpload,
  CreateOrganizationDto,
  UpdateOrganizationDto,
  AuthorizeWhatsppMemberOTPDto,
  StartDateEndDateDTO,
  IOrganizationDocuments,
} from "@/modules/Organization/dtos/organization.dto";
import {
  UpdateOrganizationPreferenceRequest,
  updateOrganizationPreferenceRequestAsMultipartRequestData,
} from "@/modules/Organization/models/UpdateOrganizationPreferenceRequest";
import { formatString } from "@/ag-portal-common/utils/helpers";
import { AddAgentDto } from "@/modules/Organization/dtos/agent.dto";
import { UpdateWebsiteConfigDto } from "../dtos/website.dto";
import { IWebsiteContent } from "@/ag-portal-common/interfaces/website.interface";
import { IPassenger } from "@/ag-portal-common/interfaces/passenger.interface";
import { OrganizationProductivityParamsRequestModel } from "../models/FetchOrganizationProductivityParams.request";

class OrganizationService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public getFormDetails(): Promise<IAGResponse> {
    return this.restClient.get(ORGANIZATION_API_PATH.ORGANIZATION_FORM);
  }

  public getAll(): Promise<IAGResponse<IOrganization[]>> {
    return this.restClient.get(ORGANIZATION_API_PATH.GET);
  }

  public getAllByCreationDate(
    payload: StartDateEndDateDTO
  ): Promise<IAGResponse<IOrganization[]>> {
    return this.restClient.get(ORGANIZATION_API_PATH.GET, payload);
  }

  public getById(id: string, params: any): Promise<IAGResponse<IOrganization>> {
    return this.restClient.get(
      `${ORGANIZATION_API_PATH.GET}${id}/`,
      params || {}
    );
  }

  public create(body: CreateOrganizationDto): Promise<IAGResponse> {
    return this.restClient.post(ORGANIZATION_API_PATH.POST, body);
  }

  public createAgent(body: AddAgentDto, orgId: string): Promise<IAGResponse> {
    return this.restClient.post(
      formatString(ORGANIZATION_API_PATH.ADD_AGENT_IN_ORGANIZATION, orgId),
      body
    );
  }

  public update(body: UpdateOrganizationDto): Promise<IAGResponse> {
    return this.restClient.patch(
      `${ORGANIZATION_API_PATH.PATCH}${body.public_id}/`,
      body
    );
  }

  public delete(organizationId: string): Promise<IAGResponse<any>> {
    return this.restClient.delete(ORGANIZATION_API_PATH.DELETE, organizationId);
  }

  public updatePreference(
    request: UpdateOrganizationPreferenceRequest,
    organizationId: string
  ): Promise<IAGResponse> {
    const formData =
      updateOrganizationPreferenceRequestAsMultipartRequestData(request);
    return this.restClient.post(
      `v2/organization/${organizationId}/organization-self-preference/update/`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  }

  public getOrganizationById(organizationId: string): Promise<IAGResponse> {
    return this.restClient.get(
      `${ORGANIZATION_API_PATH.GET_BY_ID}${organizationId}/`
    );
  }

  public getOrganizationAgentById(
    organizationId: string,
    agentId: string
  ): Promise<IAGResponse> {
    return this.restClient.get(
      formatString(
        ORGANIZATION_API_PATH.GET_ORGANIZATION_AGENT_BY_ID,
        organizationId,
        agentId
      )
    );
  }

  public updateWebsiteConfiguration(
    body: UpdateWebsiteConfigDto,
    orgId: string
  ): Promise<IAGResponse> {
    return this.restClient.post(
      formatString(ORGANIZATION_API_PATH.WEBSITE_CONFIGURATION, orgId),
      body
    );
  }

  public getWebsiteConfiguration(orgId: string): Promise<IAGResponse> {
    return this.restClient.get(
      formatString(ORGANIZATION_API_PATH.WEBSITE_CONFIGURATION, orgId)
    );
  }

  public getWebsiteContent(slug: string): Promise<IAGResponse> {
    return this.restClient.get(
      formatString(ORGANIZATION_API_PATH.WEBSITE_CONTENT, slug)
    );
  }

  public updateWebsiteContent(
    body: IWebsiteContent,
    organizationId: string
  ): Promise<IAGResponse> {
    return this.restClient.put(
      formatString(
        ORGANIZATION_API_PATH.WEBSITE_CONTENT_UPDATE,
        organizationId
      ),
      body
    );
  }

  public getOrganizationPassengers(
    organizationId: string
  ): Promise<IAGResponse> {
    return this.restClient.get(
      formatString(
        ORGANIZATION_API_PATH.GET_ORGANIZATION_PASSENGERS,
        organizationId
      )
    );
  }

  public updateOrganizationPassengerById(
    body: IPassenger,
    organizationId: string,
    passengerId: string
  ): Promise<IAGResponse> {
    return this.restClient.put(
      formatString(
        ORGANIZATION_API_PATH.UPDATE_ORGANIZATION_PASSENGERS,
        organizationId,
        passengerId
      ),
      body
    );
  }

  public createPassenger(
    body: IPassenger[],
    organizationId: string
  ): Promise<IAGResponse> {
    return this.restClient.post(
      formatString(
        ORGANIZATION_API_PATH.POST_ORGANIZATION_PASSENGERS,
        organizationId
      ),
      body
    );
  }

  public deleteOrganizationPassengerById(
    organizationId: string,
    passengerId: string
  ): Promise<IAGResponse> {
    return this.restClient.delete(
      formatString(
        ORGANIZATION_API_PATH.GET_ORGANIZATION_PASSENGERS,
        organizationId
      ),
      passengerId
    );
  }

  public organizationContentUpload(
    body: ContentUpload,
    organizationId: string
  ): Promise<IAGResponse> {
    const formdata = new FormData();
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    formdata.append(
      "image_content",
      body.image_content,
      body.image_content.name
    );
    formdata.append("content_category", body.content_category);
    formdata.append("content_sub_category", body.content_sub_category);

    return this.restClient.post(
      formatString(ORGANIZATION_API_PATH.CONTENT_UPLOAD, organizationId),
      formdata,
      config
    );
  }

  public organizationConfigUpdate(
    body: IOrganizationDocuments,
    organizationId: string
  ): Promise<IAGResponse> {
    return this.restClient.post(
      formatString(ORGANIZATION_API_PATH.CONFIG_UPDATE, organizationId),
      body
    );
  }

  public getWhatsappMembers(organizationId: string): Promise<IAGResponse> {
    return this.restClient.get(
      formatString(WHATSAPP_API_PATH.GET, organizationId)
    );
  }

  public addWhatsappMember(
    body: AddWhatsappMemberDto,
    organizationId: string
  ): Promise<IAGResponse> {
    return this.restClient.post(
      formatString(WHATSAPP_API_PATH.POST, organizationId),
      body
    );
  }

  public deleteWhatsappMembers(
    organizationId: string,
    whatsappMemberId: string
  ): Promise<IAGResponse> {
    return this.restClient.delete(
      formatString(WHATSAPP_API_PATH.DELETE_WHATSAPP, organizationId),
      whatsappMemberId
    );
  }

  public generateOTPForWhatsappMember(
    organizationId: string,
    whatsapp_member_id: string
  ): Promise<IAGResponse> {
    return this.restClient.post(
      formatString(
        WHATSAPP_API_PATH.GENERATE_OTP,
        organizationId,
        whatsapp_member_id
      ),
      {}
    );
  }

  public authorizeOTPForWhatsappMember(
    body: AuthorizeWhatsppMemberOTPDto,
    organizationId: string,
    whatsapp_member_id: string
  ): Promise<IAGResponse> {
    return this.restClient.post(
      formatString(
        WHATSAPP_API_PATH.AUTHORIZE_OTP,
        organizationId,
        whatsapp_member_id
      ),
      body
    );
  }

  public getOrganizationStats(organizationId: string): Promise<IAGResponse> {
    return this.restClient.get(
      formatString(ORGANIZATION_API_PATH.ORGANIZATION_SNAPSHOT, organizationId)
    );
  }

  public getOrganizationProductivity(
    organizationId: string,
    payload: OrganizationProductivityParamsRequestModel
  ): Promise<IAGResponse> {
    return this.restClient.get(
      formatString(
        ORGANIZATION_API_PATH.ORGANIZATION_PRODUCTIVITY,
        organizationId
      ),
      payload
    );
  }

  public getCurrencies(): Promise<IAGResponse> {
    return this.restClient.get(ORGANIZATION_API_PATH.CURRENCIES);
  }

  public getAllSaasEliteOrganizations(): Promise<IAGResponse<IOrganization[]>> {
    return this.restClient.get(ORGANIZATION_API_PATH.SAAS_ELITE_ORGANIZATION);
  }

  public updateEliteOrganizationStatus(
    organizationId: string,
    status: string
  ): Promise<IAGResponse<IOrganization[]>> {
    return this.restClient.post(
      formatString(
        ORGANIZATION_API_PATH.UPDATE_ELITE_ORGANIZATION_STATUS,
        organizationId
      ),
      {
        status,
      }
    );
  }
  public getInternalProviders(): Promise<IAGResponse> {
    return this.restClient.get(ORGANIZATION_API_PATH.GET_INTERNAL_PROVIDERS);
  }
}

export default OrganizationService;
