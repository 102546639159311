<template>
  <MCard class="applied-filters-card">
    <div class="af-header header-row">
      <div class="af-title">Applied Filters</div>
      <div class="af-action" @click="onResetAllFiltersHandler">Clear All</div>
    </div>

    <div class="af-chips">
      <template
        v-for="(filter, index) in modelValue.activeFilters"
        :key="index"
      >
        <MChip
          variant="primary"
          class="af-chip"
          border-less
          append-icon="m-cross"
          @click:append="modelValue.removeFilterByIndex(index)"
        >
          {{ onRenderFilterLabelHandler(filter) }}
        </MChip>
      </template>
    </div>
  </MCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MCard, MChip } from "@aeroglobe/ag-core-ui";

import { FlightFilters } from "../../models/filters";
import { Filter, FilterType } from "../../types";
import UTILS from "@/ag-portal-common/utils";

export default defineComponent({
  name: "AppliedFiltersCard",
  components: { MCard, MChip },
  props: {
    modelValue: {
      type: Object as PropType<FlightFilters>,
      required: true,
    },
  },
  methods: {
    onRenderFilterLabelHandler(filter: Filter): string {
      switch (filter.type) {
        case FilterType.PRICE:
          return `Upto ${this.getFormattedCurrency(filter.count ?? 0)}`;
        case FilterType.STOPS:
          return filter.label;
        case FilterType.TIMES:
          return filter.label;
        case FilterType.AIRLINES:
          return `${filter.label} (${filter.count})`;
      }
    },
    onResetAllFiltersHandler(): void {
      this.modelValue.resetAll();
    },
    getFormattedCurrency(amount: number): string {
      return UTILS.getFormattedCurrency(amount);
    },
  },
});
</script>

<style scoped lang="css">
.applied-filters-card {
  color: var(--m-secondary-color);
}

.applied-filters-card .header-row {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.applied-filters-card .af-header {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;

  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  margin-bottom: 22px;
}

.applied-filters-card .af-header .af-action {
  color: var(--m-primary-color);
  text-decoration: underline;
  cursor: pointer;

  user-select: none;
}

.af-chips {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.af-chips .af-chip {
  width: max-content;
  font-weight: 500;
}
</style>
